import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from 'components/layout';
import Head from 'components/head';
import HeaderImage from 'components/header/header-image/headerImage.js';
import Img from 'gatsby-image';

const Team = ({ data }) => {
  const workers = data.coworkersJson.workers.map(worker => (
    <div className="coworkers__item mt-9">
      <div className="coworkers__image">
        <Img fluid={worker.picture.childImageSharp.fluid} alt=""/>  
      </div>
      <div className="coworkers__content">
      <h2>{worker.title}</h2>
      </div>
    </div>
  ));
  console.log(workers);
  return (
    <Layout buttonClass="white">
      <HeaderImage title="WSPÓŁPRACOWNICY" />
      <section className="coworkers">
        <div className="container">
            <div className="coworkers__items">{workers}</div>
        </div>
     
      </section>
    </Layout>
  );
};
Team.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Team;

export const query = graphql`
  query coworkersQuery {
    coworkersJson {
      workers {
        description
        title
        picture{
          childImageSharp {
            fluid(maxHeight: 500, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;
